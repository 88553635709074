<div class="footer">
  <div class="company">
    <img [src]="league.logo.path">
    {{ league.name }}
  </div>

  <div class="mhl-logo">
    <img src="/assets/MHL-min-white.png" title="МХЛ" />
  </div>
  <a
    target="_blank"
    href="https://winline.ru/auth/registration?utm_source=mhl&utm_medium=site&utm_content=logo_header&utm_campaign=logo"
    class="winline-logo"
  >
    <img src="/assets/Winline-min.png" />
  </a>
  <a
    target="_blank"
    href="https://winline.ru/auth/registration?utm_source=mhl&utm_medium=site&utm_content=logo_header&utm_campaign=logo"
    class="winline-mobile-logo"
  >
    <img src="/assets/winline--square-logo-min.png" />
  </a>

  <div class="social">
    <a [href]="league.youtubeLink" target="_blank" *ngIf="league.youtubeLink">
      <mtg-svg-icon icon="icons-youtube"></mtg-svg-icon>
    </a>
    <a [href]="league.vkLink" target="_blank" *ngIf="league.vkLink">
      <mtg-svg-icon icon="icons-vkontakte"></mtg-svg-icon>
    </a>
    <!--<a [href]="league.fbLink" target="_blank" *ngIf="league.fbLink">
      <mtg-svg-icon icon="icons-facebook"></mtg-svg-icon>
    </a>-->
    <a [href]="league.telegramLink" target="_blank" *ngIf="league.telegramLink">
      <mtg-svg-icon icon="icons-telegram"></mtg-svg-icon>
    </a>
    <!--<a [href]="league.instaLink" target="_blank" *ngIf="league.instaLink">
      <mtg-svg-icon icon="icons-instagram"></mtg-svg-icon>
    </a>-->
    <a [href]="league.tiktokLink" target="_blank" *ngIf="league.tiktokLink">
      <mtg-svg-icon icon="icons-tiktok"></mtg-svg-icon>
    </a>
  </div>
  <div class="links">
    <a href="javascript:;" (click)="openAgreement()">Политика конфиденциальности</a>
    <a href="javascript:;" (click)="openFeedbackModal()">Обратная связь</a>
  </div>
</div>
