import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { LeagueRootService } from '@core/services/league-root.service';
import { DOCUMENT } from '@angular/common';
import { changeFavicons } from './favicon';
import {
  getGamesRoute,
  getLeagueAboutRoute,
  getLeagueContactsRoute, getLeagueDocumentsRoute, getLeagueNewsListRoute, getLeagueStandingsRoute,
  getLeagueStatisticRoute, getLeagueTeamsRoute,
  getLeagueTournamentsRoute, getTournamentRoute
} from '@config/routes';
import { LayoutService } from './layout/layout.service';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { SeoService } from '@core/services/seo.service';
import { applyTokens } from '@utils/tokens';

@Component({
  selector: 'mtg-root',
  template: '<router-outlet></router-outlet>'
})
export class RootComponent implements OnInit {
  constructor(
    private leagueRootService: LeagueRootService,
    @Inject(DOCUMENT) private doc: Document,
    private layoutService: LayoutService,
    private seoService: SeoService,
    private renderer2: Renderer2,
  ) {
  }

  ngOnInit(): void {
    combineLatest([
      this.leagueRootService.league$.pipe(take(1)),
      this.leagueRootService.seasonTournaments$.pipe(take(1))
    ])
      .subscribe(([league, seasonTournaments]) => {
        applyTokens(this.doc, this.renderer2, league.id);
        this.layoutService.setLeagueMenu([
          {title: 'Главная', routerLink: ['/'], exact: true},
          // ...(seasonTournaments
          //     ? [{
          //       title: seasonTournaments[0].season.name,
          //       routerLink: getTournamentRoute(seasonTournaments[0].alias),
          //       subMenu: seasonTournaments
          //         .map(item => ({
          //           title: item.season.name,
          //           routerLink: getTournamentRoute(item.alias)
          //         }))
          //     }]
          //     : []
          // ),
          // {title: 'Турниры', routerLink: getLeagueTournamentsRoute()},
          {title: 'Команды', routerLink: getLeagueTeamsRoute()},
          {title: 'Статистика', routerLink: getLeagueStatisticRoute()},
          ...(league.about ? [{title: 'О лиге', routerLink: getLeagueAboutRoute()}] : []),
          ...(league.documentsCount > 0 ? [{title: 'Документы', routerLink: getLeagueDocumentsRoute()}] : []),
          {title: 'Контакты', routerLink: getLeagueContactsRoute()},
        ]);
        this.layoutService.setMainRoute(['/']);
        if (league.cover.path) {
          this.layoutService.setDefaultCover(league.cover.path);
        } else {
          this.layoutService.setDefaultCover('');
        }

        this.seoService.setDefaultData({
          title: league.name,
          description: league.description,
          image: league.logo.path,
        });
      });
  }
}
