import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import {
  componentDestroyed,
  Game,
  handleError,
  UntilDestroy
} from '@mtgame/core';
import { GamesHeadlineService } from '../../services/games-headline.service';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@UntilDestroy
@Component({
  selector: 'mtg-games-headline',
  templateUrl: './games-headline.component.html',
  styleUrls: ['./games-headline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GamesHeadlineComponent implements OnInit, OnDestroy {
  games: Game[];
  loading = false;

  @HostBinding('class.hidden')
  get hiddenClass() {
    return this.games && this.games.length === 0;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private gamesHeadlineService: GamesHeadlineService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.gamesHeadlineService.games$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(games => {
        this.games = games;
        this.cdr.markForCheck();
      })
  }

  ngOnDestroy(): void {
    this.gamesHeadlineService.dispose();
  }

  async loadNextPage(): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) {
      return Promise.resolve();
    }
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      await this.gamesHeadlineService.loadNextPage();
    } catch (e) {
      handleError(e);
    }
    this.loading = false;
  }
}
