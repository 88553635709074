import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Game, GameStatuses } from '@mtgame/core';
import { getGameRoute } from '@config/routes';
import { getTournamentShortName, getTourTitle } from '@utils/hardcode';

@Component({
  selector: 'mtg-game-carousel-headline-card',
  templateUrl: './game-carousel-headline-card.component.html',
  styleUrls: ['./game-carousel-headline-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameCarouselHeadlineCardComponent {
  @Input()
  game: Game;
  @Input()
  showGroup: boolean;
  gameStatuses = GameStatuses;

  get gameRoute(): string[] {
    return getGameRoute(this.game.id);
  }

  get currentPeriod(): number {
    return Object.keys(this.game.scoreByPeriod || {}).length || 1;
  }

  get tournamentName(): string {
    return getTournamentShortName(this.game.tournament.name);
  }

  getTourTitle(tour: number): string {
    return getTourTitle(tour, this.game.team.sport, true);
  }
}
