import { Renderer2 } from '@angular/core';

export type TokenStyles = {
  colorPrimary?: string;
  colorPrimaryText?: string;
  colorSecondary?: string;
  colorSecondaryText?: string;
  colorCardBase?: string;
  colorControlBase?: string;
  colorPanel?: string;
  colorBackground?: string;
  colorControlBorder?: string;
  colorControlElement?: string;
  colorTextSecondary?: string;
  colorChipsPrimary?: string;
  colorChipsSecondary?: string;
  colorFont?: string;
};

const LEAGUE_TOKENS: {[key: number]: TokenStyles} = {};
// LEAGUE_TOKENS[12] = {
//   colorPrimary: '#E23746',
//   colorCardBase: '#1B1B25',
//   colorControlBase: '#242430',
//   colorPanel: '#1B1B25',
//   colorBackground: '#111219',
//   colorControlBorder: 'rgba(255, 255, 255, .2)',
//   colorControlElement: 'rgba(255, 255, 255, .2)',
// };
LEAGUE_TOKENS[19] = {
  colorPrimary: '#FFBB0D',
  colorPrimaryText: '#23208C',
  colorSecondary: '#FFBB0D',
  colorSecondaryText: '#23208C',
  colorCardBase: '#302DAA',
  colorControlBase: '#3849C2',
  colorPanel: '#3330b3',
  colorBackground: '#272492',
  colorControlBorder: rgba('#A6A4EC', .2),
  colorControlElement: '#A6A4EC',
  colorTextSecondary: '#A6A4EC',
  colorChipsPrimary: adjustColor('#FFBB0D', -8),
  colorChipsSecondary: adjustColor('#3849C2', -8),
};
LEAGUE_TOKENS[14] = {
  colorPrimary: '#C31D23',
  colorPrimaryText: '#FFFFFF',
  colorSecondary: '#C31D23',
  colorSecondaryText: '#FFFFFF',
  colorCardBase: '#181818',
  colorControlBase: '#242424',
  colorPanel: '#1d1d1d',
  colorBackground: '#070707',
  colorControlBorder: rgba('#737373', .2),
  colorControlElement: '#737373',
  colorTextSecondary: '#737373',
  colorChipsPrimary: adjustColor('#C31D23', -8),
  colorChipsSecondary: adjustColor('#242424', -8),
};
LEAGUE_TOKENS[34] = {
  colorPrimary: '#C31D23',
  colorPrimaryText: '#FFFFFF',
  colorSecondary: '#C31D23',
  colorSecondaryText: '#FFFFFF',
  colorCardBase: '#001818',
  colorControlBase: '#001C1C',
  colorPanel: '#001313',
  colorBackground: '#002323',
  colorControlBorder: rgba('#617272', .2),
  colorControlElement: '#617272',
  colorTextSecondary: '#617272',
  colorChipsPrimary: adjustColor('#C31D23', -8),
  colorChipsSecondary: adjustColor('#001C1C', -8),
};
LEAGUE_TOKENS[37] = {
  colorPrimary: '#E3BB80',
  colorPrimaryText: '#1C2134',
  colorSecondary: '#E3BB80',
  colorSecondaryText: '#1C2134',
  colorCardBase: '#2E375B',
  colorControlBase: '#414D7C',
  colorPanel: '#282e50',
  colorBackground: '#1C2134',
  colorControlBorder: rgba('#9BA6D2', .2),
  colorControlElement: '#9BA6D2',
  colorTextSecondary: '#9BA6D2',
  colorChipsPrimary: adjustColor('#E3BB80', -8),
  colorChipsSecondary: adjustColor('#414D7C', -8),
};

LEAGUE_TOKENS[106] = {
  colorPrimary: '#ff8c16',
  colorPrimaryText: '#0071BC',
  colorSecondary: '#ff8c16',
  colorSecondaryText: '#1F3766',
  colorCardBase: '#3794D2',
  colorControlBase: '#1F3766',
  colorPanel: '#2979af',
  colorBackground: '#004979',
  colorControlBorder: rgba('#FFFFFF', 1),
  colorControlElement: '#a6b5d3',
  colorTextSecondary: '#FFFFFF',
  colorChipsPrimary: adjustColor('#ff8c16', -8),
  colorChipsSecondary: adjustColor('#414D7C', -8),
};

LEAGUE_TOKENS[12] = {
  colorPrimary: '#ff6a13',
  colorChipsPrimary: adjustColor('#ff6a13', -8),
  colorPanel: '#2d2d38',
  colorCardBase: '#2d2d38',
  colorControlBase: '#3a3a48',
  colorChipsSecondary: adjustColor('#2d2d38', -16),
};

export function applyTokens(document: Document, renderer: Renderer2, leagueId: number) {
  const tokens = LEAGUE_TOKENS[leagueId] || {};
  if (tokens.colorPrimary) {
    setColorTokens(document, renderer, 'color-primary', tokens.colorPrimary);
    setColorTokens(document, renderer, 'color-primarybuttonfocus', rgba(tokens.colorPrimary, .4), false);
  }
  if (tokens.colorPrimaryText) {
    setColorTokens(document, renderer, 'color-primary-text', tokens.colorPrimaryText, false);
  }
  if (tokens.colorSecondary) {
    setColorTokens(document, renderer, 'color-secondary', tokens.colorSecondary);
  }
  if (tokens.colorCardBase) {
    setColorTokens(document, renderer, 'color-cardbase', tokens.colorCardBase);
  }
  if (tokens.colorControlBase) {
    setColorTokens(document, renderer, 'color-controlbase', tokens.colorControlBase);
    setColorTokens(document, renderer, 'color-secondarybuttonfocus', rgba(tokens.colorControlBase, .4), false);
  }
  if (tokens.colorPanel) {
    setColorTokens(document, renderer, 'color-panel', tokens.colorPanel);
  }
  if (tokens.colorBackground) {
    setColorTokens(document, renderer, 'color-background', tokens.colorBackground);
  }
  if (tokens.colorControlBorder) {
    setColorTokens(document, renderer, 'color-controlborder', tokens.colorControlBorder);
  }
  if (tokens.colorControlElement) {
    setColorTokens(document, renderer, 'color-controlelement', tokens.colorControlElement);
  }
  if (tokens.colorTextSecondary) {
    setColorTokens(document, renderer, 'color-textsecondary', tokens.colorTextSecondary, false);
  }
  if (tokens.colorChipsPrimary) {
    setColorTokens(document, renderer, 'color-chipsprimary', tokens.colorChipsPrimary);
  }
  if (tokens.colorChipsSecondary) {
    setColorTokens(document, renderer, 'color-chipssecondary', tokens.colorChipsSecondary);
  }
  if (tokens.colorFont) {
    setColorTokens(document, renderer, 'color-colorfont', tokens.colorFont, false);
  }
}

function setColorTokens(document: Document, renderer: Renderer2, tokenName: string, color: string, withStates = true): void {
  document.body.style.setProperty(`--${tokenName}`, color);
  // renderer.setStyle(document.body, `--${tokenName}`, color);
  if (withStates) {
    document.body.style.setProperty(`--${tokenName}--hover`, adjustColor(color, -4));
    document.body.style.setProperty(`--${tokenName}--active`, adjustColor(color, -12));
    document.body.style.setProperty(`--${tokenName}--lighten`, adjustColor(color, 4));
    // renderer.setStyle(document.body, `--${tokenName}--hover`, adjustColor(color, -4));
    // renderer.setStyle(document.body, `--${tokenName}--active`, adjustColor(color, -12));
    // renderer.setStyle(document.body, `--${tokenName}--lighten`, adjustColor(color, 4));
  }
}

function adjustColor(color: string, percent: number): string {
  const amount = Math.round((percent / 100) * 255);
  if (color.substr(0, 1) === '#') {
    const rgb = hexToRgb(color);
    return rgbToHex(rgb.r + amount, rgb.g + amount, rgb.b + amount);
  } else if (color.substr(0 , 5) === 'rgba(') {
    const rgba = color.replace(/[^\d,]/g, '').split(',');
    return `rgba(${+rgba[0] + amount}, ${+rgba[1] + amount}, ${+rgba[2] + amount}, ${rgba[3]})`;
  } else if (color.substr(0, 4) === 'rgb(') {
    const rgb = color.replace(/[^\d,]/g, '').split(',');
    return rgbToHex((+rgb[0]) + amount, (+rgb[1]) + amount, (+rgb[2]) + amount);
  }
}

function hexToRgb(hex: string): {r: number, g: number, b: number} {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function componentToHex(c: number) {
  if (c > 255) {
    c = 255;
  }
  if (c < 0) {
    c = 0;
  }
  const hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function rgba(color: string, opacity: number): string {
  const rgb = hexToRgb(color);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
}

