import { Injectable } from '@angular/core';
import {
  BaseService,
  CentrifugoService,
  League,
  LeagueApi,
  Tournament, TournamentApi,
  TournamentSeason,
  TournamentSeasonApi,
  TournamentSeasonStatuses,
} from '@mtgame/core';
import { Observable } from 'rxjs';
import { GamesHeadlineService } from '../../games-headline/services/games-headline.service';

const STORE_LEAGUE_KEY = 'league';
const STORE_SEASON_KEY = 'season';
const STORE_SEASONS_KEY = 'seasons';
const STORE_LIVE_STATISTIC_KEY = 'live-stat';
const STORE_TOURNAMENT_KEY = 'tournament';
const STORE_TOURNAMENTS_KEY = 'tournaments';
const STORE_SEASON_TOURNAMENTS_KEY = 'seasonTournaments';

@Injectable({providedIn: 'root'})
export class LeagueRootService extends BaseService {
  private leagueId: number;

  get league$(): Observable<League> {
    return this.store.get(STORE_LEAGUE_KEY);
  }

  get season$(): Observable<TournamentSeason> {
    return this.store.get(STORE_SEASON_KEY);
  }

  get seasons$(): Observable<TournamentSeason[]> {
    return this.store.get(STORE_SEASONS_KEY);
  }

  get tournament$(): Observable<Tournament> {
    return this.store.get(STORE_TOURNAMENT_KEY);
  }

  get tournaments$(): Observable<Tournament[]> {
    return this.store.get(STORE_TOURNAMENTS_KEY);
  }

  get seasonTournaments$(): Observable<Tournament[]> {
    return this.store.get(STORE_SEASON_TOURNAMENTS_KEY);
  }

  get liveStatistic$(): Observable<any> {
    return this.store.get(STORE_LIVE_STATISTIC_KEY);
  }

  constructor(
    private leagueApi: LeagueApi,
    private tournamentSeasonApi: TournamentSeasonApi,
    private tournamentApi: TournamentApi,
    private centrifugoService: CentrifugoService,
    private gamesHeadlineService: GamesHeadlineService,
  ) {
    super();
  }

  async initialize(domain: string): Promise<League> {
    const league = await this.getLeague(domain);
    this.leagueId = league.id;
    this.gamesHeadlineService.initialize({leagueId: this.leagueId});
    await this.getActiveSeason();
    const tournaments = await this.getSeasonTournaments();
    if (tournaments.length === 0) {
      await this.getActiveTournament();
    }
    return league;
  }

  dispose(): void {
    super.dispose();
    this.centrifugoService.unsubscribe(`league_${this.leagueId}`);
  }

  async getLeague(domain: string): Promise<League> {
    const league = await this.leagueApi.getByDomain(domain);
    this.store.save(STORE_LEAGUE_KEY, league);
    return league;
  }

  async getSeasonTournaments(): Promise<Tournament[]> {
    const seasonTournaments = await this.leagueApi.getSeasonTournaments(this.leagueId);
    if (seasonTournaments.length === 0) {
      this.store.save(STORE_SEASON_TOURNAMENTS_KEY, []);
      return [];
    }
    const tournaments = await this.tournamentApi.getSeasonTournaments(seasonTournaments[0].id);
    this.store.save(STORE_SEASON_TOURNAMENTS_KEY, tournaments);
    this.store.save(STORE_TOURNAMENT_KEY, tournaments[0]);
    this.store.save(STORE_TOURNAMENTS_KEY, [tournaments[0]]);
    return tournaments;
  }

  async getActiveSeason(): Promise<TournamentSeason> {
    const seasons = await this.leagueApi.getTournamentSeasons(this.leagueId);
    this.store.save(STORE_SEASONS_KEY, seasons);
    let activeSeason: TournamentSeason;
    const activeSeasons = seasons.filter(item => item.status !== TournamentSeasonStatuses.closed);
    if (activeSeasons.length > 0) {
      activeSeason = activeSeasons[0];
    } else {
      activeSeason = seasons[0];
    }
    this.store.save(STORE_SEASON_KEY, activeSeason || null);
    return activeSeason;
  }

  async getActiveTournament(): Promise<Tournament[]> {
    const season = this.store.value(STORE_SEASON_KEY);
    if (!season) {
      return;
    }
    const tournaments = await this.tournamentSeasonApi.getTournaments(season.id);
    this.store.save(STORE_TOURNAMENTS_KEY, tournaments);
    this.store.save(STORE_TOURNAMENT_KEY, tournaments[0]);
    return tournaments;
  }

  getLeagueModel(): League {
    return this.store.value(STORE_LEAGUE_KEY);
  }

  getActiveSeasonModel(): TournamentSeason {
    return this.store.value(STORE_SEASON_KEY);
  }

  getActiveSeasonsModel(): TournamentSeason[] {
    return this.store.value(STORE_SEASONS_KEY);
  }

  getActiveTournamentModel(): Tournament {
    return this.store.value(STORE_TOURNAMENT_KEY);
  }
}
