<a [routerLink]="gameRoute">
  <div class="date-card">
    <div class="time">
      {{ game.datetime | date: 'HH:mm' }}
    </div>
    <div class="date">
      {{ game.datetime | date: 'dd MMM' }}
    </div>
    <div class="live" [ngClass]="{active: game.status === gameStatuses.in_progress}">
      <span class="dod"></span> LIVE
    </div>
  </div>
  <div class="game-card">
    <div class="content">
      <ng-container
        *ngFor="let team of game.mhlCarousel.teams; let i = index;"
      >
        <div class="team">
          <div class="logo">
            <mtg-svg-icon class="award-left" icon="custom-award-left" *ngIf="game.mhlCarousel.winnerTeamId === team.team.id"></mtg-svg-icon>
            <img [src]="team.team.logo.path" *ngIf="team.team.logo.path">
            <div [ngClass]="team.team.getEmptyLogoClass()" *ngIf="!team.team.logo.path"></div>
            <mtg-svg-icon class="award-right" icon="custom-award-right" *ngIf="game.mhlCarousel.winnerTeamId === team.team.id"></mtg-svg-icon>
          </div>
          <div class="name">{{ team.team.name }}</div>
          <div class="score">{{ team.score }}</div>
        </div>
        <div
          *ngIf="i < game.mhlCarousel.teams.length - 1"
          class="divider"
        >:</div>
      </ng-container>
    </div>
    <div class="footer">
      <div class="court">
        <mtg-svg-icon icon="icons-pin"></mtg-svg-icon>
        {{ game.tournamentCourt.name }}
      </div>
      <div class="tournament">
        <span class="tournament-logo">
          <img [src]="game.tournament.logo.path">
        </span>
        {{ tournamentName }}
      </div>
    </div>
  </div>
</a>
