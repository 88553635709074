import { Sport, SportTypes } from '@mtgame/core';

export const EXCLUDE_GAMES_TOURNAMENT_ID = [87, 129];
export const EXCLUDE_HEADLINE_GAMES = [5468, 5470, 5475, 5461, 5460, 5482, 5478, 5479, 9344, 8803, 9344, 9779, 9368, 9781, 51445, 51442, 51644, 51643];
export const GALO_TOURNAMENT_IDS = [87, 129];
export const GROUPED_STATISTIC_SPORT_TYPES = [SportTypes.hockey, SportTypes.football, SportTypes.handball];

export const HOCKEY_REGULAR_ROUNDS_IDS = [11];
export const HOCKEY_PLAYOFF_ROUNDS_IDS = [12];

export const TEAM_PHOTO_FIELD_ID = 1;
export const TEAM_UNIFORM_FIELD_ID = 2;

export const DOCUMENT_FIELD_IDS = [26, 27, 28, 29, 30];

export function getTournamentShortName(name: string): string {
  return name.replace('Дивизион "', '')
    .replace(/"/gi, '')
    .replace('(2 раунд)', '2')
    .replace('Новогодний ТРиумф - дивизион', '')
    .replace('Товарищеские матчи', 'Тов. матч');
}

export function getTourTitle(tour: number, sport: Sport, short?: boolean): string {
  return tour ? `${tour} тур` : 'Плей-офф';
}
