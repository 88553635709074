<ng-container *ngIf="league">
  <mtg-games-headline></mtg-games-headline>
  <mtg-header></mtg-header>
  <div *ngIf="backgroundImage"
       class="cover"
       [style]="{backgroundImage: 'url(' + backgroundImage + ')'}"
  ></div>
  <div class="relative-wrapper">
    <div class="wrapper">
      <div class="content">
        <div class="head-row">
          <mtg-team-and-user-search class="search"></mtg-team-and-user-search>
        </div>
        <router-outlet></router-outlet>
      </div>
      <mtg-footer></mtg-footer>
    </div>
  </div>

  <mtg-icons></mtg-icons>
  <mtg-check-version></mtg-check-version>
</ng-container>
<ng-container *ngIf="!league">
  <div class="relative-wrapper">
    <div class="wrapper">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>
